import Axios from "axios";

export function Get<T = any>(endpoint: string, data?: any) {
  return Axios.get<T>(endpoint, { data: data });
}

export function Post<T = any>(endpoint: string, data: any) {
  return Axios.post<T>(endpoint, data);
}

export function Put<T = any>(endpoint: string, data: any) {
  return Axios.put<T>(endpoint, data);
}

export function Delete<T = any>(endpoint: string) {
  return Axios.delete<T>(endpoint);
}
