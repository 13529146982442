import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  authLayoutRoutes,
  dashboardLayoutRoutes,
  simpleLayoutRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import SimpleLayout from "../layouts/Simple";
import Page404 from "../pages/auth/Page404";

const childRoutes = (Layout: any, routes: any) => {
  return routes.map(
    (
      {
        component: Component,
        guard,
        children,
        path,
        featureCodes,
      }: {
        component: any;
        guard: any;
        children: any;
        path: any;
        featureCodes: any;
      },
      index: number
    ) => {
      const Guard = guard || React.Fragment;
      const AuthGuard = ({ children }: { children: any }) => {
        return Guard === React.Fragment ? (
          <Guard>{children}</Guard>
        ) : (
          <Guard featureCodes={featureCodes}>{children}</Guard>
        );
      };

      return children ? (
        children.map((element: any, index: number) => {
          const Guard = element.guard || React.Fragment;
          const AuthGuard = ({ children }: { children: any }) => {
            return Guard === React.Fragment ? (
              <Guard>{children}</Guard>
            ) : (
              <Guard featureCodes={featureCodes}>{children}</Guard>
            );
          };
          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props) => (
                <AuthGuard
                  children={
                    <Layout>
                      <element.component {...props} />
                    </Layout>
                  }
                />
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <AuthGuard
              children={
                <Layout>
                  <Component {...props} />
                </Layout>
              }
            />
          )}
        />
      ) : null;
    }
  );
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
        {childRoutes(SimpleLayout, simpleLayoutRoutes)}
        {childRoutes(AuthLayout, authLayoutRoutes)}
        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
