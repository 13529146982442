import React from "react";
import { AppState } from "../models/AppState";
// create auth context with default value

// set backup default for isAuthenticated if none is provided in Provider
const AppContext = React.createContext<AppState>({
  isAuthenticated: false,
  loading: true,
  user: null,
  setUser: () => {},
});
export default AppContext;
