import React from "react";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import DateFnsUtils from "@date-io/date-fns";
import "./i18n/config";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { THEMES } from "./constants";
import { User } from "./models/User";
import AppContext from "./context/AppContext";
import { isLoggedIn } from "./services/authService";
import { LAST_USER } from "./constants/cookies";
import Loader from "./components/Loader";

const insertionPoint = document.getElementById("jss-insertion-point");

const jss = create({
  ...jssPreset(),
  insertionPoint: insertionPoint ?? "",
});

Sentry.init({
  dsn:
    "https://6e511625abf34651a36edb6d88c5e14c@o424481.ingest.sentry.io/5908947",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  const [user, setUser] = React.useState<User | null>(null);
  const theme = { currentTheme: THEMES.LIGHT };
  const [loading, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const u = await isLoggedIn();
        if (active) {
          localStorage.removeItem(LAST_USER);
          localStorage.setItem(LAST_USER, u.name);
          setUser(u);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        if (active) {
          setLoading(false);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <React.Fragment>
      <AppContext.Provider
        value={{
          user: user,
          isAuthenticated: !!user,
          setUser: setUser,
          loading: loading,
        }}
      >
        <Helmet
          titleTemplate="%s | EMR"
          defaultTitle="Simposium Digital Healthcare - EMR"
        />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <React.Suspense fallback={<Loader />}>
                  <Routes />
                </React.Suspense>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </AppContext.Provider>
    </React.Fragment>
  );
}

export default App;
