export enum Role {
  Admin = "Admin",
  User = "User",
  Demo = "Demo",
  BI = "BI",
}

export function GetRoleName(role: Role | undefined | string) {
  switch (role) {
    case Role.Admin: {
      return "Administrator";
    }
    case Role.User: {
      return "User";
    }
    case Role.BI: {
      return "BI";
    }
    default: {
      return "Demo";
    }
  }
}
