import { List, Home as HomeIcon, Users, UploadCloud } from "react-feather";
import {
  HOME_PATH,
  REPORT_ADD_I_PATH,
  REPORT_ADD_D_PATH,
  REPORT_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  REPORT_OPEN_PATH,
  PATHOLOGY_ADD_PATH,
  REPORT_EXTERNAL_PATH,
  CAMPAIGN_LIST_PATH,
  UPLOAD_PATH,
} from "../constants/routes";
import React from "react";

// Guards
const AuthGuard = React.lazy(() => import("../components/AuthGuard"));

// Auth components
const SignIn = React.lazy(() => import("../pages/auth/SignIn"));
const SignOut = React.lazy(() => import("../pages/auth/SignOut"));

// Protected components
const Home = React.lazy(() => import("../pages/dashboard/Home"));
const ReportManage = React.lazy(() => import("../pages/report/List"));
const ReportOpen = React.lazy(() => import("../pages/report/Open"));
const AddInstitutionalReport = React.lazy(
  () => import("../pages/report/AddInstitutional")
);
const AddItemReport = React.lazy(() => import("../pages/report/AddItem"));
const AddPathologyReport = React.lazy(
  () => import("../pages/report/AddPathology")
);

const ListData = React.lazy(() => import("../pages/data/List"));
const UploadData = React.lazy(() => import("../pages/data/Upload"));

// This route is only visible while signed in
const homeRoute = {
  id: "Dashboard",
  path: HOME_PATH,
  header: "Dashboard",
  icon: <HomeIcon />,
  containsHome: true,
  component: Home,
  guard: AuthGuard,
};

const uploadListRoute = {
  id: "Manage campaigns",
  path: CAMPAIGN_LIST_PATH,
  header: "Content",
  icon: <UploadCloud />,
  containsHome: false,
  component: ListData,
  guard: AuthGuard,
};

const uploadDataRoute = {
  id: "Upload Data",
  path: UPLOAD_PATH,
  component: UploadData,
  guard: AuthGuard,
};

const reportManageRoute = {
  id: "Report",
  path: REPORT_PATH,
  header: "Report",
  icon: <List />,
  containsHome: false,
  component: ReportManage,
  guard: AuthGuard,
};

const reportOpenRoute = {
  id: "ReportOpen",
  path: REPORT_OPEN_PATH,
  component: ReportOpen,
  guard: AuthGuard,
};

const reportShareRoute = {
  id: "ReportShare",
  path: REPORT_EXTERNAL_PATH,
  component: ReportOpen,
};

const institutionalReportRoute = {
  id: "Add institutional report",
  path: REPORT_ADD_I_PATH,
  component: AddInstitutionalReport,
  guard: AuthGuard,
};

const itemReportRoute = {
  id: "Add item report",
  path: REPORT_ADD_D_PATH,
  component: AddItemReport,
  guard: AuthGuard,
};

const pathologyReportRoute = {
  id: "Add pathology report",
  path: PATHOLOGY_ADD_PATH,
  component: AddPathologyReport,
  guard: AuthGuard,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: SIGN_IN_PATH,
      name: "Sign In",
      component: SignIn,
    },
    {
      path: SIGN_OUT_PATH,
      name: "Sign Out",
      component: SignOut,
      guard: AuthGuard,
    },
  ],
  component: null,
};

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes on the sidebar
export const sidebarLayoutRoutes = [
  homeRoute,
  reportManageRoute,
  uploadListRoute,
];

// Routes with dashboard
export const dashboardLayoutRoutes = [
  homeRoute,
  reportManageRoute,
  institutionalReportRoute,
  itemReportRoute,
  pathologyReportRoute,
  reportOpenRoute,
  uploadListRoute,
  uploadDataRoute,
];

// Routes with Simple layout
export const simpleLayoutRoutes = [reportShareRoute];
