import { User } from "../models/User";
import { Delete, Get, Post } from "../utils/axios";

export function signIn(username: string, password: string): Promise<User> {
  return new Promise((resolve, reject) => {
    Post<User>("/api/auth/sign-in", { username, password })
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function signInWithToken(
  accessToken: string,
  refreshToken: string
): Promise<User> {
  return new Promise((resolve, reject) => {
    Post<User>("/api/auth/sign-in-with-token", { accessToken, refreshToken })
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function isLoggedIn(): Promise<User> {
  return new Promise((resolve, reject) => {
    Get<User>("/api/auth")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function signOut() {
  return Delete("/api/auth");
}
