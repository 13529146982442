import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import detectionOptions from "./detectionConfig.json";
import PT from "./pt/translation.json";

export const resources = {
  pt: {
    translation: PT,
  },
} as const;

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    fallbackLng: "en",
    resources,
    debug: false,
    keySeparator: false,
    detection: detectionOptions,
  });
