// Routes
export const HOME_PATH = "/home";
export const REPORT_PATH = "/report/manage";
export const REPORT_OPEN_PATH = "/report/:id";
export const REPORT_EXTERNAL_PATH = "/report/external/:hash";
export const REPORT_ADD_I_PATH = "/report/add/institutional";
export const PATHOLOGY_ADD_PATH = "/report/add/pathology";
export const REPORT_ADD_D_PATH = "/report/add/item";
export const SIGN_IN_PATH = "/";
export const SIGN_OUT_PATH = "/auth/sign-out";
export const CHANGELOG_PATH = "/changelog";
export const NOT_FOUND_PATH = "/404";
export const UPLOAD_PATH = "/content/upload";
export const CAMPAIGN_LIST_PATH = "/content/list";
